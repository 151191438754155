var isSP = function() {
	return $('.sp-view:visible').length != 0;
};

// Retina対応
document.createElement('picture');

// scroll
$(function(){
	$('a[href^="#"]' + 'a:not(.non-scroll)').on('click', function(){
    var speed = 800;
    var href= $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top;
    $('html, body').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
});

// SP header menu
$(function() {
	$('.header__menu').on('click', function() {
		$(this).toggleClass('active');
		$('body').toggleClass('show');
	});
	
	$(function() {
		$('.gnav__item--search').click(function() {
			$('.header').toggleClass('search-show');
		});
	});
	
    $('.gnav a[href*="#"]').on('click', function(){
		if (isSP()) {
			$(".header__menu").removeClass('active');
			$("body").removeClass('show');
		}
    });
    
    $('.gnav__btn').on('click', function() {
		$('body').removeClass('show');
		$('body,html').css({"overflow":"visible","height":"auto"});
		$(".header__menu").removeClass('active');
    });	
});

// accordion
$(function() {
	$('.accordion').click(function() {
		$(this).next().slideToggle();
		$(this).toggleClass('active');
		
	});
	
	$('.sp-accordion').click(function() {
		if (isSP()) {
			$(this).next().slideToggle();
			$(this).toggleClass('active');
		}
	});
});

// .panel__body__link
$(function() {
  $(".panel__body__link").hover(function() {
    $(this).parents(".panel").toggleClass('panel__body-link-hover');
  });
});

// .post__body__link
$(function() {
  $(".post__body__link").hover(function() {
    $(this).parents(".post").toggleClass('post__body-link-hover');
  });
});

// .ウォッチリストに追加しました
$(function() {
	$('.ttl-company__message__btn').click(function() {
		$(this).parents(".ttl-company__message").addClass('ttl-company__message--close');
	});
});



// SNSボタン

function social_button() {
	var url   = encodeURI( location.href );
	var title = encodeURI( document.title );

	var social_button = document.querySelectorAll( '.social-button' );

	if ( social_button.length == 0 ) {
		return;
	}

	social_button.forEach(function( li ) {

		if ( li.querySelector( '._twitter-tweet_ a' ) != null ) {
			li.querySelector( '._twitter-tweet_ a' ).setAttribute( 'href', '//twitter.com/share?text=' + title + '&url=' + url );
		}

		if ( li.querySelector( '._facebook-share_ a' ) != null ) {
			li.querySelector( '._facebook-share_ a' ).setAttribute( 'href', '//www.facebook.com/sharer.php?t=' + title + '&u=' + url );
		}

		if ( li.querySelector( '._hatena-bookmark_ a' ) != null ) {
			li.querySelector( '._hatena-bookmark_ a' ).setAttribute( 'href', '//b.hatena.ne.jp/add?mode=confirm&title=' + title + '&url=' + url );
		}

		if ( li.querySelector( '._line-send_ a' ) != null ) {
			li.querySelector( '._line-send_ a' ).setAttribute( 'href', '//social-plugins.line.me/lineit/share?url=' + url );
		}

	});
}


if ( document.readyState == 'loading' ) {
	document.addEventListener( 'DOMContentLoaded', function() {
		social_button();
	}, false );
} else {
	social_button();
}